import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LeagueTypeSelector from '../components/LeagueTypeSelector';
import DraftTypeSelector from '../components/DraftTypeSelector';
import TeamSelector from '../components/TeamSelector';
import DraftPositionSelector from '../components/DraftPositionSelector';
import RosterPositionSelector from '../components/RosterPositionSelector';
import axios from 'axios';

const DraftSetup = () => {
    const [leagueType, setLeagueType] = useState(1);     // AFL Fantasy
    const [draftType, setDraftType] = useState(1);    // Snake
    const [numberOfTeams, setNumberOfTeams] = useState(10);
    const [draftPosition, setDraftPosition] = useState(1);
    const [rosterPositions, setRosterPositions] = useState({
        Defenders: 2,
        Midfielders: 4,
        Ruck: 1,
        Forwards: 3,
        Utility: 1,
        Bench: 4,
    });

    const navigate = useNavigate();

    const handleStartDraft = async () => {
        const draftSettings = {
            league_type: leagueType,
            draft_type: draftType,
            number_of_teams: numberOfTeams,
            user_draft_position: draftPosition,
            defenders: rosterPositions.Defenders,
            forwards: rosterPositions.Forwards,
            midfielders: rosterPositions.Midfielders,
            rucks: rosterPositions.Ruck,
            benches: rosterPositions.Bench,
            utilities: rosterPositions.Utility,
        };
        const newDraft = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/drafts/`, draftSettings);
        const newDraftId = newDraft.data.draft.id;
        const startDraft = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/drafts/${newDraftId}/start_draft/`, draftSettings);

        navigate(`/draft/${startDraft.data.draft_id}`, { state: { ...draftSettings } });
    };

    return (
        <div className="draft-setup-page min-h-screen flex flex-col items-center justify-between">
            {/* Header */}
            <header className="w-full bg-blue-600 mb-8 relative shadow-2xl">
                <div className="max-w-4xl mx-auto flex items-center justify-center space-x-4">
                    {/* Header Text */}
                    <h1 className="text-4xl font-bold text-white relative">
                        AFL Draft Pros
                    </h1>
                    {/* Logo Image */}
                    <img
                        src={`../logo.png`}
                        alt="AFL Draft Pros"
                        className="w-24 h-24"
                    />
                </div>
            </header>

            {/* Main Content */}
            <div className="w-full max-w-md bg-white shadow-lg rounded-lg py-8 px-16 space-y-4 mb-8">
                <h2 className="text-center text-xl font-bold text-gray-800 px-8">
                    Build Your Ultimate AFL Fantasy Team
                </h2>
                <LeagueTypeSelector onChange={setLeagueType} />
                <DraftTypeSelector onChange={setDraftType} />
                <TeamSelector onChange={setNumberOfTeams} />
                <DraftPositionSelector maxPosition={numberOfTeams} onChange={setDraftPosition} />
                <RosterPositionSelector onChange={setRosterPositions} />
                <button
                    onClick={handleStartDraft}
                    className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                >
                    Start Draft
                </button>
            </div>

            {/* Footer */}
            <footer className="w-full bg-blue-700 py-4 text-center">
                <p className="text-white text-sm">© 2025 AFL Draft Pros. All Rights Reserved.</p>
            </footer>
        </div>

    );
};

export default DraftSetup;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import PlayerCard from '../components/PlayerCard';
import CategorySelector from '../components/CategorySelector';
import Loader from '../components/Loader';
import Swal from 'sweetalert2';
import PositionCard from '../components/PositionCard';
import DraftTable from '../components/DraftTable';
import debounce from "lodash.debounce";

const Draft = () => {
    let { draftId } = useParams();
    const location = useLocation();
    const [draft, setDraft] = useState([]);
    const [topPlayers, setTopPlayers] = useState(null);
    const [selectedTopPlayers, setSelectedTopPlayers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isFinished, setIsFinished] = useState(false);
    const [category, setCategory] = useState('All');
    const navigate = useNavigate();
    const [state, setState] = useState(location.state);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const searchRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchPlayers, setSearchPlayers] = useState([]);
    const [groupedPlayers, setGroupedPlayers] = useState({});
    const [benchPlayers, setBenchPlayers] = useState([]);
    const [utilityPlayers, setUtilityPlayers] = useState([]);

    const handleSearchClick = () => {
        setIsSearchVisible(true);
    };

    const handleCheatSheetClick = async () => {
        try {
            setSelectedTopPlayers([]);
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/drafts/${draftId}/search_players/`, {
                params: { searchTerm: 'all' },
            });
            const draft_resp = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/drafts/${draftId}/get_draft`);

            const players = Array.isArray(response.data) ? response.data : [];
            const draftPlayers = Array.isArray(draft_resp.data.draft) ? draft_resp.data.draft : [];

            // Extract player_id from the draft data
            const draftedPlayerIds = draftPlayers.map(draftPlayer => draftPlayer.player_id);

            // Group players by position
            const groupedPlayers = players.reduce((acc, player) => {
                acc[player.primary_position.name] = acc[player.primary_position.name] || [];
                acc[player.primary_position.name].push({
                    ...player,
                    isDrafted: draftedPlayerIds.includes(player.id), // Add flag for drafted players
                });
                return acc;
            }, {});

            setGroupedPlayers(groupedPlayers);
        } catch (error) {
            console.error("Error searching players", error);
        }
    };
    const handleShowModal = async () => {
        try {
            await getDraft();
        } catch (error) {
            console.error("Error in handleShowModal", error);
        }
    }
    const getDraft = async () => {
        try {
            const draft_resp = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/drafts/${draftId}/get_draft`);
            const newDraft = draft_resp.data.draft;
            // Default sorting by round and ID
            newDraft.sort((a, b) => (a.round_number === b.round_number ? a.id - b.id : a.round_number - b.round_number));
            const draft_type = location.state.draft_type;
            const number_of_teams = location.state.number_of_teams;

            const groupedByRound = [];
            let currentRound = null;
            let currentGroup = [];

            // Grouping by round
            newDraft.forEach(pick => {
                if (pick.round_number !== currentRound) {
                    if (currentGroup.length > 0) {
                        groupedByRound.push(currentGroup);
                    }
                    currentRound = pick.round_number;
                    currentGroup = [pick];
                } else {
                    currentGroup.push(pick);
                }
            });
            if (currentGroup.length > 0) {
                groupedByRound.push(currentGroup);
            }

            // Handling snake draft if applicable
            if (draft_type === 1) {
                setDraft(groupedByRound.map((group, index) => {
                    let newGroup = group;
                    if (newGroup.length !== number_of_teams) {
                        newGroup = newGroup.concat(Array(number_of_teams - newGroup.length).fill(''));
                    }
                    if (index % 2) {
                        newGroup = newGroup.reverse();
                    }
                    return newGroup;
                }));
            } else {
                setDraft(groupedByRound);
            }

            const isDraftFinished = draft_resp.data.current_round > draft_resp.data.total_rounds;

            if (isDraftFinished) {
                // Define custom position order
                const positionOrder = ['Defender', 'Midfielder', 'Ruck', 'Forward', 'Utility', 'Bench'];

                const positionKeys = {
                    'Defender': 'defenders',
                    'Midfielder': 'midfielders',
                    'Ruck': 'rucks',
                    'Forward': 'forwards',
                    'Utility': 'utilities',
                    'Bench': 'benches',
                }
                const teamPlayersCount = {}

                const teams = {};
                newDraft.forEach(player => {
                    const sortPosition = player.picked_position === "False" ? player.position_name : player.picked_position;
                    if (teams[player.team_id] === undefined) {
                        teams[player.team_id] = [];
                        teamPlayersCount[player.team_id] = []
                    }

                    if (!teamPlayersCount[player.team_id][sortPosition])
                        teamPlayersCount[player.team_id][sortPosition] = 0

                    teamPlayersCount[player.team_id][sortPosition] += 1

                    const newPlayer = {
                        ...player,
                        isActive: teamPlayersCount[player.team_id][sortPosition] <= location.state[positionKeys[sortPosition]] ? 2 : 0
                    }
                    teams[player.team_id].push(newPlayer);
                });
                let teamOrder = Object.keys(teams);
                const teamToMove = teamOrder[0];
                const targetPosition = location.state.user_draft_position - 1;  // Zero-based index
                teamOrder.splice(0, 1);
                teamOrder.splice(targetPosition, 0, teamToMove);  // Insert at the user_draft_position-th position
                Object.keys(teams).forEach(teamId => {
                    const players = teams[teamId];
                    const utilitiesCount = location.state.utilities;
                    let utilityPlayers = [];
                    const inactivePlayers = players.filter(player => player.isActive === 0);
                    const ruckPlayers = inactivePlayers.filter(player => player.position_name === 'Ruck');
                    utilityPlayers = utilityPlayers.concat(ruckPlayers.slice(0, utilitiesCount));
                    if (utilityPlayers.length < utilitiesCount) {
                        const remainingSlots = utilitiesCount - utilityPlayers.length;
                        const otherInactivePlayers = inactivePlayers.filter(player => player.position_name !== 'Ruck');
                        const additionalUtilities = otherInactivePlayers.slice(0, remainingSlots);
                        utilityPlayers = utilityPlayers.concat(additionalUtilities);
                    }

                    utilityPlayers.forEach(player => {
                        player.isActive = 1;
                    });

                    players.sort((a, b) => {
                        const aPosition = a.picked_position === "False" ? a.position_name : a.picked_position;
                        const bPosition = b.picked_position === "False" ? b.position_name : b.picked_position;
                        if (a.isActive !== b.isActive) return b.isActive - a.isActive;
                        return positionOrder.indexOf(aPosition) - positionOrder.indexOf(bPosition);
                    });
                });


                // Generate HTML for columns by team
                const columnsHTML = teamOrder.map(teamId => {

                    setTimeout(() => setLoading(false), 1000);
                    const teamPlayers = teams[teamId];
                    return `
                    <div class="w-full sm:w-1/2 lg:w-1/4 p-4 bg-white rounded-lg shadow-md">
                        <div class="grid grid-cols-1 gap-4">
                            ${teamPlayers.map(player => {
                        // Get the class for each player's position and state (picked or not)
                        const playerClass = getPositionCardClass(player);
                        // Use picked_position if available, else fallback to position_name
                        const positionDisplay = player.picked_position === "False" ? player.position_name : player.picked_position;

                        return `
                        <div class="${playerClass} h-16 content-center">
                            <div class="text-sm font-semibold text-gray-700">${player.player_name}</div>
                            <div class="text-xs text-gray-500">${positionDisplay}</div>
                        </div>`;
                    }).join('')}
                        </div>
                    </div>`;
                })
                    .join('');

                Swal.fire({
                    title: 'Draft Finished!',
                    html: `
                        <div class="flex justify-around space-x-4">
                            ${columnsHTML}
                        </div>`,
                    icon: 'success',
                    confirmButtonText: `<i class="fa fa-thumbs-up"></i> OK!`,
                    width: '90%',
                    customClass: {
                        container: 'swal-container',
                        title: 'swal-title',
                        htmlContainer: 'swal-html-container'
                    }
                });

                setIsFinished(true);
            } else {
                // Fetch top players if the draft is not finished
                const top_players_resp = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/drafts/${draftId}/get_top_available_players`);
                setTopPlayers(top_players_resp.data);
            }
        } catch (error) {
            console.error("Error fetching draft data", error);
        }
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setIsSearchVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchRef]);

    useEffect(() => {
        const loadDraft = async () => {
            await getDraft();
            setTimeout(() => setLoading(false), 1000);
        };

        loadDraft();
    }, [draftId]);

    useEffect(() => {
        if (topPlayers && category !== 'Cheat Sheet')
            if (category === 'All') {
                setSelectedTopPlayers(topPlayers["Defender"].slice(0, 3).concat(topPlayers["Midfielder"].slice(0, 3), topPlayers["Forward"].slice(0, 3), topPlayers["Ruck"].slice(0, 1)))
            }
            else {
                setSelectedTopPlayers(topPlayers[category]);
            }
    }, [category, topPlayers]);

    // const handlePick = async (player) => {
    //     setLoading(true);
    //     try {
    //         let selectedPos = player.primary_position.name; // Default to primary position

    //         // Apply priority rules if the player has a secondary position
    //         if (player.secondary_position) {
    //             const primary = player.primary_position.name;
    //             const secondary = player.secondary_position.name;

    //             if (primary === 'Forward' || secondary === 'Forward') {
    //                 selectedPos = state.forwards > 0 ? 'Forward' : state[primary] > 0 ? primary : secondary;
    //             } else if (primary === 'Defender' || secondary === 'Defender') {
    //                 selectedPos = state.defenders > 0 ? 'Defender' : state[primary] > 0 ? primary : secondary;
    //             } else if (primary === 'Ruck' || secondary === 'Ruck') {
    //                 selectedPos = state.rucks > 0 ? 'Ruck' : state[primary] > 0 ? primary : secondary;
    //             } else {
    //                 selectedPos = state[primary] > 0 ? primary : secondary; // Default fallback
    //             }
    //         }
    //         // Post the pick to the backend
    //         await axios.post(`${process.env.REACT_APP_BACKEND_URL}/drafts/${draftId}/make_pick/`, {
    //             player_id: player.id,
    //             team_position: location.state.user_draft_position,
    //             picked_position: selectedPos,
    //         });

    //         const positions = {
    //             Defender: 'defenders',
    //             Forward: 'forwards',
    //             Midfielder: 'midfielders',
    //             Ruck: 'rucks',
    //         };

    //         if (state[positions[selectedPos]] > 0) {
    //             setState((prevState) => ({
    //                 ...prevState,
    //                 [positions[selectedPos]]: prevState[positions[selectedPos]] - 1,
    //             }));
    //         } else if (state.utilities > 0) {
    //             // Deduct from utility if position slots are full
    //             setUtilityPlayers((prevState) => [...prevState, player.id]);
    //             setState((prevState) => ({
    //                 ...prevState,
    //                 utilities: prevState.utilities - 1,
    //             }));
    //         } else {
    //             setBenchPlayers((prevState) => [...prevState, player.id]);
    //             setState((prevState) => ({
    //                 ...prevState,
    //                 benches: prevState['benches'] - 1,
    //             }));
    //         }
    //         await getDraft();
    //     } catch (error) {
    //         console.error(error);
    //         Swal.fire({
    //             title: error.response?.data?.error || 'Error',
    //             text: 'The draft has been completed successfully.',
    //             icon: 'error',
    //             confirmButtonText: 'OK',
    //             timer: 3000,
    //         });
    //     } finally {
    //         setTimeout(() => setLoading(false), 1000);
    //     }
    // };

    const handlePick = async (player) => {
        setLoading(true);
        try {
            let selectedPos = player.primary_position.name;;
            if (player.secondary_position) {
                const { value: position } = await Swal.fire({
                    title: 'Choose Position',
                    input: 'radio',
                    inputOptions: {
                        [player.primary_position.name]: `Pick as ${player.primary_position.name}`,
                        [player.secondary_position.name]: `Pick as ${player.secondary_position.name}`,
                    },
                    inputValidator: (value) => {
                        if (!value) {
                            return 'You need to choose a position!';
                        }
                    },
                    confirmButtonText: 'Confirm',
                    showCancelButton: true,
                });
                if (!position) {
                    selectedPos = player.primary_position.name;
                    setLoading(false);
                    return; // User canceled the selection
                }
                console.log(position)
                selectedPos = position;
            }
                
            // Post the pick to the backend
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/drafts/${draftId}/make_pick/`, {
                player_id: player.id,
                team_position: location.state.user_draft_position,
                picked_position: selectedPos, // Send the selected position
            });

            const positions = {
                Defender: 'defenders',
                Forward: 'forwards',
                Midfielder: 'midfielders',
                Ruck: 'rucks',
            };

            
            if (state[positions[selectedPos]] > 0) {
                setState((prevState) => ({
                    ...prevState,
                    [positions[selectedPos]]: prevState[positions[selectedPos]] - 1,
                }));
            } else if (state.utilities > 0) {
                // Deduct from utility if position slots are full
                setUtilityPlayers(prevState => ([...prevState, player.id]));
                setState((prevState) => ({
                    ...prevState,
                    utilities: prevState.utilities - 1,
                }));
            }
            else {
                setBenchPlayers(prevState => ([...prevState, player.id]));
                setState((prevState) => ({
                    ...prevState,
                    benches: prevState['benches'] - 1,
                }));
            }

            await getDraft();
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: error.response?.data?.error || 'Error',
                text: 'The draft has been completed successfully.',
                icon: 'error',
                confirmButtonText: 'OK',
                timer: 3000,
            });
        } finally {
            setTimeout(() => setLoading(false), 1000);
        }
    };

    const resetSearch = () => {
        setSearchTerm('');
        setSearchPlayers([]); // Optionally clear the search results
    };

    const debouncedSearch =
        useCallback(debounce(async (inputValue) => {
            if (inputValue) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/drafts/${draftId}/search_players/`, {
                        params: { searchTerm: inputValue },
                    });
                    const players = Array.isArray(response.data) ? response.data : [];
                    const filteredPlayers = players.filter(player => !draft.flat().some(pick => pick.id === player.id));
                    setSearchPlayers(filteredPlayers);
                } catch (error) {
                    console.error("Error searching players", error);
                }
            }
        }, 500), [setSearchPlayers]
        )

    const handleSearchTerm = (e) => {
        const inputValue = e.target.value;
        setSearchTerm(inputValue); // Update search term immediately
        debouncedSearch(inputValue); // Call debounced function
    };

    const getPositionCardClass = (player) => {
        const positionClass = (() => {
            if (player.isActive === 0)
                return 'bg-gray-200 text-gray-800';
            if (player.isActive === 1) {
                return 'bg-red-200 text-gray-800'; // Utility player styling
            }
            switch (player.picked_position === "False" ? player.position_name : player.picked_position) {
                case 'Forward':
                    return 'bg-green-200 text-gray-800';
                case 'Midfielder':
                    return 'bg-yellow-200 text-gray-800';
                case 'Defender':
                    return 'bg-blue-200 text-gray-800';
                case 'Ruck':
                    return 'bg-purple-200 text-gray-800';
                default:
                    return 'bg-gray-200 text-gray-800';
            }
        })();

        // Define border class for picked players
        const borderClass = player.picked_position !== 'False' ? 'border-2 border-yellow-500' : '';

        // Check if player is a bench player or utility player and apply special styling
        const playerClass = (() => {
            if (benchPlayers.includes(player.player_id) && player.picked_position !== 'False') {
                return `bg-gray-200 text-gray-800 rounded-lg mb-2 border-2 border-yellow-500`;
            }
            if (utilityPlayers.includes(player.player_id) && player.picked_position !== 'False') {
                return `bg-red-200 text-gray-800 rounded-lg mb-2 border-2 border-yellow-500`;
            }
            return `${positionClass} ${borderClass} rounded-lg mb-2`;
        })();
        return playerClass;
    };

    
    return (
        <div className="relative">
            <div className="relative z-10">
                {loading ? (
                    <div className="loader-container">
                        <Loader />
                    </div>
                ) : (
                    <div>
                        {isFinished ?
                            <div className='w-full flex justify-end'>
                                <button
                                    className="bg-blue-600 text-white font-bold px-6 py-2 rounded-full shadow-lg hover:bg-blue-700 hover:shadow-xl transition duration-300 mx-8"
                                    onClick={handleShowModal}
                                >
                                    Show Draft Summary
                                </button>
                                <button
                                    onClick={() => navigate('/')}
                                    className="bg-blue-600 text-white font-bold px-6 py-2 rounded-full shadow-lg hover:bg-blue-700 hover:shadow-xl transition duration-300"
                                >
                                    New Draft
                                </button>
                            </div>
                            :
                            <>
                                <CategorySelector
                                    category={category}
                                    setCategory={setCategory}
                                    searchTerm={searchTerm}
                                    handleSearchTerm={handleSearchTerm} // Use the debounced function here
                                    isSearchVisible={isSearchVisible}
                                    searchPlayers={searchPlayers}
                                    handleSearchClick={handleSearchClick}
                                    handleCheatSheetClick={handleCheatSheetClick}
                                    searchRef={searchRef}
                                    handlePick={handlePick}
                                    resetSearch={resetSearch}
                                />

                                <div className='flex'>
                                    <div className="p-4">
                                        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                                            <PositionCard
                                                title="Defender"
                                                count={state.defenders}
                                                bgColor="bg-blue-100"
                                                iconAlt="Defender"
                                            />
                                            <PositionCard
                                                title="Midfielder"
                                                count={state.midfielders}
                                                bgColor="bg-yellow-100"
                                                iconAlt="Midfielder"
                                            />
                                            <PositionCard
                                                title="Ruck"
                                                count={state.rucks}
                                                bgColor="bg-purple-100"
                                                iconAlt="Ruck"
                                            />
                                            <PositionCard
                                                title="Forward"
                                                count={state.forwards}
                                                bgColor="bg-green-100"
                                                iconAlt="Forward"
                                            />
                                            <PositionCard
                                                title="Utility"
                                                count={state.utilities}
                                                bgColor="bg-red-100"
                                                iconAlt="Utility"
                                            />
                                            <PositionCard
                                                title="Bench"
                                                count={state.benches}
                                                bgColor="bg-grey-100"
                                                iconAlt="Bench"
                                            />
                                        </div>
                                    </div>
                                    {selectedTopPlayers.length > 0 ?
                                        <div className="flex-auto grid grid-cols-1 md:grid-cols-5 grid-rows-2 gap-4 p-4">
                                            {selectedTopPlayers.slice(0, 10).map((selectedTopPlayer) => (
                                                <div key={selectedTopPlayer.id} className="m-4 fade-in">
                                                    <PlayerCard player={selectedTopPlayer} pick={handlePick} />
                                                </div>
                                            ))}
                                        </div>
                                        : ""}
                                    {selectedTopPlayers.length > 0 ? "" :
                                        <div className="flex-auto mt-6 grid grid-cols-4 gap-4 px-8">
                                            {['Defender', 'Midfielder', 'Ruck', 'Forward'].map((position) => (
                                                <div key={position} className="space-y-4">
                                                    {groupedPlayers[position]?.length > 0 && (
                                                        <>
                                                            <h3 className="text-sm lg:text-lg font-semibold text-gray-800 dark:text-gray-200 text-center">
                                                                {position}
                                                            </h3>
                                                            <ul className="space-y-1">
                                                                {groupedPlayers[position].map((player) => {
                                                                    return (
                                                                    <li
                                                                        key={player.id}
                                                                        className={`p-1 rounded-lg shadow border text-xs text-center ${player.isDrafted
                                                                            ? 'bg-red-200 dark:bg-red-700 border-red-400' // Color for drafted players
                                                                            : 'bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700' // Default color
                                                                            }`}
                                                                    >
                                                                        <div className="text-gray-900 dark:text-gray-100">
                                                                            <p>{player.name} ({player.team.abb_name})</p>
                                                                            {/* Add more player details if needed */}
                                                                        </div>
                                                                    </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                        </div>

                                    }
                                </div>
                            </>
                        }
                        <DraftTable
                            draft={draft}
                            userDraftPosition={location.state.user_draft_position}
                            numberOfTeams={location.state.number_of_teams}
                            getPositionCardClass={getPositionCardClass}
                            benchPlayers={benchPlayers}
                            utilityPlayers={utilityPlayers}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Draft;

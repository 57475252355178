import React from 'react';

const PlayerCard = ({ player, pick }) => {
    console.log(player);
    return (
        <div className="relative bg-gradient-to-r from-gray-100 to-gray-200 shadow-lg rounded-lg p-4 w-full h-64 flex flex-col transition-transform transform hover:scale-105 duration-300 ease-in-out opacity-90">
            <div className="flex items-center justify-center">
                <img
                    src={`../assets/player/${player.photo_url}.webp`}
                    alt={player.name}
                    className="w-20 h-20 rounded-full object-cover shadow-lg border-4 border-white"
                />
            </div>
            <div className="flex items-center justify-center">
                <div className="text-center">
                    <div className="font-bold text-xm w-28 text-gray-800">{player.name}</div>
                    <div className="text-blue-400 text-xs mt-2 gap-2">
                        {player.secondary_position != null && player.primary_position.id > player.secondary_position.id ? (
                            <>
                                <span>{player.secondary_position.name}</span>
                                <span>| {player.primary_position.name}</span>
                            </>
                        ) : player.secondary_position != null && player.secondary_position.id > player.primary_position.id ? (
                            <>
                                <span>{player.primary_position.name}</span>
                                <span>| {player.secondary_position.name}</span>
                            </>
                        ) : (
                            <span>{player.primary_position.name}</span>
                        )}
                    </div>
                    <div className="text-red-400 text-xs mt-1">2024 Score: {player.avg_score}</div>
                </div>
            </div>
            <img
                src={`../assets/logo/${player.team.id}.png`}
                alt={player.team.name}
                className="absolute top-2 right-2 w-10 h-10 object-contain"
            />
            <div className="absolute bottom-2 left-0 right-10 flex justify-end">
                <button
                    onClick={() => pick(player)}
                    className="bg-blue-600 text-white font-bold px-6 py-2 rounded-full shadow-lg hover:bg-blue-700 hover:shadow-xl transition duration-300"
                >
                    Draft
                </button>
            </div>
        </div>
    );
};

export default PlayerCard;

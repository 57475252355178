import React from "react";
import DraftCell from "./DraftCell";

const DraftTable = ({ draft, userDraftPosition, numberOfTeams, getPositionCardClass }) => {
  return (
    <table className="w-full bg-white shadow-lg rounded-lg overflow-hidden mt-16 border-none opacity-90">
      <thead>
        <tr className="bg-blue-600 text-white">
          {Array(numberOfTeams).fill('').map((_, index) => (
            <th
              key={index}
              className={`px-4 py-3 text-center ${
                userDraftPosition - 1 === index ? 'bg-green-500 text-white' : 'bg-blue-600'
              }`}
            >
              {userDraftPosition - 1 === index ? 'Your Team' : `AI ${index + 1}`}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {draft.length > 0 &&
          draft.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={`${rowIndex % 2 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100`}
            >
              {row.map((player, colIndex) => 
              (
                <DraftCell
                  key={colIndex}
                  player={player}
                  isUserColumn={userDraftPosition - 1 === colIndex}
                  getPositionCardClass={getPositionCardClass}
                />
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default DraftTable;

import React, { useState } from 'react';

const RosterPositionSelector = ({ onChange }) => {
    const [positions, setPositions] = useState({
        Defenders: 2,
        Midfielders: 4,
        Ruck: 1,
        Forwards: 3,
        Utility: 1,
        Bench: 4,
    });

    const handlePositionChange = (position, value) => {
        const updatedPositions = { ...positions, [position]: parseInt(value, 10) };
        setPositions(updatedPositions);
        onChange(updatedPositions);
    };

    return (
        <div className="roster-position-selector">
            {Object.entries(positions).map(([position, count]) => (
                <div key={position} className="flex items-center justify-between mb-4">
                    <label
                        htmlFor={position}
                        className="w-1/2 text-sm font-medium text-gray-700"
                    >
                        {position}:
                    </label>
                    <input
                        type="number"
                        id={position}
                        value={count}
                        min="1"
                        max={position === 'Midfielders' ? 9 : position === 'Ruck' ? 2 : 6}
                        onChange={(e) => handlePositionChange(position, e.target.value)}
                        className="w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                </div>
            ))}
        </div>
    );
};

export default RosterPositionSelector;

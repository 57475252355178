import React from "react";

const DraftCell = ({ player, isUserColumn, getPositionCardClass }) => {
  console.log(player);
  return (
    <td
      className={`w-32 h-48 text-center align-top border-none ${isUserColumn ? 'bg-green-100' : ''
        }`}
    >
      {player ? (
        <div
          className={`flex flex-col items-center p-3 rounded-lg shadow-md h-full ${isUserColumn ? 'border-4 border-green-400' : ''
            } ${getPositionCardClass(player)}`}
        >
          <img
            src={`../assets/player/${player.player_photo_url}.webp`}
            alt={player.player_name}
            className="w-16 h-16 rounded-full object-cover mb-2"
          />
          <img
            src={`../assets/logo/${player.team_name}.png`}
            alt={player.team_name}
            className="w-10 h-10 object-cover mb-2"
          />
          <div className="font-semibold text-lg">{player.player_name}</div>
          <div className="text-sm">
            {player.secondary_position_name ? (
              player.player_primary_position_id > player.player_secondary_position_id ? (
                `${player.secondary_position_name} / ${player.primary_position_name}`
              ) : (
                `${player.primary_position_name} / ${player.secondary_position_name}`
              )
            ) : (
              player.primary_position_name
            )}
          </div>
        </div>
      ) : (
        <div className="text-gray-400 h-full flex items-center justify-center">Empty</div>
      )}
    </td>
  );
};

export default DraftCell;

import React, { useState } from 'react';
import PlayerSearchCard from './PlayerSearchCard';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const CategorySelector = ({
  category,
  setCategory,
  searchTerm,
  handleSearchTerm,
  isSearchVisible,
  searchPlayers,
  handleSearchClick,
  searchRef,
  handlePick,
  handleCheatSheetClick,
  resetSearch
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 mt-8">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between px-4">
        <div className="block mb-4 md:mb-0">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchTerm}
            onClick={handleSearchClick}
            className="px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-200 w-full md:w-64"
            placeholder="Search players..."
          />
          {isSearchVisible && searchPlayers && searchTerm && (
            <ul className="absolute left-0 w-96 mt-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-lg z-50 max-h-96 overflow-y-auto">
              {searchPlayers.map((searchPlayer) => (
                <li key={searchPlayer.id} className="m-2">
                  <PlayerSearchCard player={searchPlayer} pick={handlePick} resetSearch={resetSearch} />
                </li>
              ))}
            </ul>
          )}
        </div>
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className="md:hidden text-gray-50 dark:text-gray-700 focus:outline-none"
        >
          {menuOpen ? <div className='flex items-center'><XMarkIcon className="h-6 w-6" /><p>filter players</p></div> : <div className='flex items-center'><Bars3Icon className="h-6 w-6" /><p>filter players</p></div>}
        </button>

        <ul
          className={`flex-col md:flex-row md:flex space-x-4 transition-all duration-200 ${menuOpen ? 'flex' : 'hidden'
            } md:flex`}
        >
          {['All', 'Defender', 'Midfielder', 'Forward', 'Ruck'].map((cat) => (
            <li key={cat} className="mt-2 md:mt-0">
              <a
                className={`inline-block px-6 py-3 rounded-lg transition-all duration-200 
              ${category === cat
                    ? 'text-white bg-blue-600 dark:bg-blue-800 font-semibold'
                    : 'text-gray-600 hover:text-white hover:bg-blue-500 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white'}`}
                onClick={() => setCategory(cat)}
              >
                {cat}
              </a>
            </li>
          ))}
          <li className="mt-2 md:mt-0">
            <a
              className={`inline-block px-6 py-3 rounded-lg transition-all duration-200 
            ${category === 'Cheat Sheet'
                  ? 'text-white bg-blue-600 dark:bg-blue-800 font-semibold'
                  : 'text-gray-600 hover:text-white hover:bg-blue-500 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white'}`}
              onClick={() => { setCategory('Cheat Sheet'); handleCheatSheetClick(); }}
            >
              Cheat Sheet
            </a>
          </li>
        </ul>
      </div>
    </div>

  );
};

export default CategorySelector;

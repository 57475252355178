import React from "react";

const PositionCard = ({ title, count, bgColor, iconAlt }) => {
  return (
    <div
      className={`p-4 ${bgColor} rounded-lg shadow-xl flex items-center space-x-4 transition-transform transform hover:scale-105 hover:shadow-2xl overflow-hidden`}
    >
      <img
        src="/ball.png"
        alt={iconAlt}
        className="w-16 h-10 object-contain"
      />
      <div className="flex flex-col flex-grow text-center md:text-left">
        <p className="text-sm font-medium text-gray-700 line-clamp-1">{title}</p>
        <p className="text-2xl font-extrabold text-gray-800 line-clamp-1">{count}</p>
      </div>
    </div>
  );
};

export default PositionCard;

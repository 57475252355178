import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(async () => {
            try {
                const newSession = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/drafts/new_session/`);
                localStorage.setItem('Session', newSession.data.id);
                axios.defaults.headers.common['Session'] = newSession.data.id;
                navigate('/draft-setup');
            } catch (error) {
                console.error('Error fetching new session:', error);
                // Optionally, handle error state or redirect to an error page
            }
        }, 5000); // Delay of 5 seconds
    
        return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
    }, [navigate]);
    
    return (
        <div className="w-screen h-screen bg-cover bg-center flex flex-col justify-center items-center relative" style={{ backgroundImage: "url('background.jpg')" }}>
            {/* Background Overlay */}
            <div className="absolute inset-0 bg-black opacity-40"></div>
            
            {/* Title */}
            <div className="relative text-white text-4xl md:text-6xl font-bold mb-8 drop-shadow-lg px-8 shadow-black">
                AFL Draft Pros Mock Simulator
            </div>
            
            {/* Spinner */}
            <div className="relative w-24 aspect-square grid animate-spin">
                <div className="absolute w-full h-full border-8 border-red-500 border-t-transparent border-b-transparent rounded-full"></div>
                <div className="absolute w-full h-full border-8 border-blue-500 border-l-transparent border-r-transparent rounded-full animate-reverse-spin"></div>
            </div>
        </div>
    );
};

export default Home;

import React, { useState } from 'react';

const DraftPositionSelector = ({ maxPosition, onChange }) => {
    const [draftPosition, setDraftPosition] = useState(1);

    const handlePositionChange = (event) => {
        const position = parseInt(event.target.value, 10);
        setDraftPosition(position);
        onChange(position);
    };

    return (
        <div className="draft-position-selector mb-6 flex items-center">
            <label htmlFor="draftPosition" className="w-1/2 text-sm font-medium text-gray-700">
                Draft Position:
            </label>
            <select
                id="draftPosition"
                value={draftPosition}
                onChange={handlePositionChange}
                className="w-1/2 px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
                {Array.from({ length: maxPosition }, (_, i) => i + 1).map((pos) => (
                    <option key={pos} value={pos}>{pos}</option>
                ))}
            </select>
        </div>
    );
};

export default DraftPositionSelector;

import React, { useState } from 'react';

const TeamSelector = ({ onChange }) => {
    const [numberOfTeams, setNumberOfTeams] = useState(10);

    const handleTeamChange = (event) => {
        const teams = parseInt(event.target.value, 10);
        setNumberOfTeams(teams);
        onChange(teams);
    };

    return (
        <div className="team-selector mb-6 flex items-center">
            <label htmlFor="numberOfTeams" className="w-1/2 text-sm font-medium text-gray-700">
                Number of Teams:
            </label>
            <input
                type="number"
                id="numberOfTeams"
                value={numberOfTeams}
                min="6"
                max="16"
                onChange={handleTeamChange}
                className="w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
        </div>
    );
};

export default TeamSelector;
